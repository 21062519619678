.wrap {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;

  &__main {
    flex: 1;
    
    @include respond-to("s-768") {
      width: calc(100% - #{$header-width-dk});
    }
  }

  &__top {
    background: #ededed;
    padding: 1rem 1rem 1rem 5rem;
    border-bottom: 1px solid #ccc;
    display: flex;
    height: 71px;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    padding: 1.5rem;
    background: #f7f7f7;
  }
}
* {
  margin: 0;
  padding: 0;
}

// Root
html {
  font-size: 50%; 

  @include respond-to("s-520") { 
    font-size: 56.25%;
  }

  @include respond-to("s-992") {
    font-size: 62.5%;
  }

}

html,
body{
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: map-get($color-map, primary);
  min-height: 100vh;
  padding: 0;
  margin: 0!important;
  background: map-get($color-map, "alabaster");
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

hr {
  border-color: map-get($color-map, secondary);

  @include when-inside("footer") {
    border-color: map-get($color-map, merlin);
  }
}

// LoVe-HAte
a:link,
a:visited {
  color: map-get($color-map, primary);
  text-decoration: none;
  transition: all 250ms;
}

a:hover,
a:active {
  color: darken(map-get($color-map, primary), 20%)
}


// Form Base
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  appearance: none;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  outline: 0;
}

label,
legend {
  display: block;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}


// Collezione FontSize + LineHeight + LetterSpacing
@each $macro, $macroMap in $typeSetting {
  .size-#{$macro},
  %size-#{$macro} {
    @each $prop, $val in $macroMap {
        @if($prop) == 'default'{
          @each $prop1, $val1 in $val {
            #{$prop1}:#{$val1}
          }
        } @else {
          @media screen and (min-width: #{$prop+"px"}) {
            @each $prop1, $val1 in $val {
              #{$prop1}:#{$val1}
            }
          }
        }
    }
  }
}

// colori
@each $color-key, $color-val in $color-map {
  .color-#{$color-key},
  %color-#{$color-key} {
    color: #{$color-val};
  }
  .bg-#{$color-key},
  %color-#{$color-key} {
    background-color: #{$color-val};
  }
}

// Regole generiche dei componenti
.livedit {
  @extend %clearfix; 
  position: relative;
}

.debug-off pre {
  display: none
}
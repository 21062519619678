.row + .row {
  margin-top: 15px;
}

.row [class*="col-"] + [class*="col-"] {
  margin-top: 15px;
}

@media (min-width: 1200px) {
  .row [class*="col-lg-"] + [class*="col-lg-"] {
      margin-top: 0;
  }
}
@media (min-width: 992px) {
  .row [class*="col-md-"] + [class*="col-md-"] {
      margin-top: 0;
  }
}
@media (min-width: 768px) {
  .row [class*="col-sm-"] + [class*="col-sm-"] {
      margin-top: 0;
  }
}
// Container
$flexgrid-gutter: 1rem;

.grid {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  // spaziature orizzontali
  .grid--gutters {
    margin: -#{$flexgrid-gutter};
  }
  
  .grid--gutters > * {
    margin: $flexgrid-gutter;
  }
  
  .grid--flexCells > * {
    display: flex;
  }
  
  .grid--top {
    align-items: flex-start;
  }
  
  .grid--bottom {
    align-items: flex-end;
  }
  
  .grid--center {
    align-items: center;
  }
  
  .grid--justifyCenter {
    justify-content: center;
  }

  .grid-cell--top {
    align-self: flex-start;
  }
  
  .grid-cell--bottom {
    align-self: flex-end;
  }
  
  .grid-cell--center {
    align-self: center;
  }
  
  .grid-cell--autoSize {
    flex: none;
  }
  
  .grid--fit > * {
    flex: 1;
  }
  
  .grid--full > * {
    flex: 0 0 calc(100% - #{$flexgrid-gutter} * 2);
  }
  
  .grid--1of2 > * {
    flex: 0 0 calc(50% - #{$flexgrid-gutter} * 2);
  }
  
  .grid--1of3 > * {
    flex: 0 0 calc(33.3333% - #{$flexgrid-gutter} * 2);
  }
  
  .grid--1of4 > * {
    flex: 0 0 calc(25% - #{$flexgrid-gutter} * 2);
  }

  .grid--1of6 > * {
    flex: 0 0 calc(16.6666667% - #{$flexgrid-gutter} * 2);
  }
  
  @media (min-width: 520px) {
    .small-grid--fit > * {
      flex: 1;
    }
    .small-grid--full > * {
      flex: 0 0 calc(100% - #{$flexgrid-gutter} * 2);
    }
    .small-grid--1of2 > * {
      flex: 0 0 calc(50% - #{$flexgrid-gutter} * 2);
    }
    .small-grid--1of3 > * {
      flex: 0 0 calc(33.3333% - #{$flexgrid-gutter} * 2);
    }
    .small-grid--1of4 > * {
      flex: 0 0 calc(25% - #{$flexgrid-gutter} * 2);
    }
    .small-grid--1of6 > * {
      flex: 0 0 calc(33.3333% - #{$flexgrid-gutter} * 2);
    }

  }
  
  @media (min-width: 768px) {
    .med-grid--fit > * {
      flex: 1;
    }
    .med-grid--full > * {
      flex: 0 0 calc(100% - #{$flexgrid-gutter} * 2);
    }
    .med-grid--1of2 > * {
      flex: 0 0 calc(50% - #{$flexgrid-gutter} * 2);
    }
    .med-grid--1of3 > * {
      flex: 0 0 calc(33.3333% - #{$flexgrid-gutter} * 2);
    }
    .med-grid--1of4 > * {
      flex: 0 0 calc(25% - #{$flexgrid-gutter} * 2);
    }
    .med-grid--1of6 > * {
      flex: 0 0 calc(33.3333% - #{$flexgrid-gutter} * 2);
    }
  }
  
  @media (min-width: 992px) {
    .large-grid--fit > * {
      flex: 1;
    }
    .large-grid--full > * {
      flex: 0 0 calc(100% - #{$flexgrid-gutter} * 2);
    }
    .large-grid--1of2 > * {
      flex: 0 0 calc(50% - #{$flexgrid-gutter} * 2);
    }
    .large-grid--1of3 > * {
      flex: 0 0 calc(33.3333% - #{$flexgrid-gutter} * 2);
    }
    .large-grid--1of4 > * {
      flex: 0 0 calc(25% - #{$flexgrid-gutter} * 2);
    }
    .large-grid--1of6 > * {
      flex: 0 0 calc(16.6666667% - #{$flexgrid-gutter} * 2);
    }
  }
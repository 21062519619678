.icon {
    
  width: 20px;
  height: 20px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  transition: fill 250ms;

  // tutte le icone documenti
  &[class|="icon icon-ext"] {
    width: 32px;
    height: 32px;
  }

  &-ext-xls {fill: #267446}
  &-ext-doc {fill: #375595}
  &-ext-pdf {fill: #E9353C}
  &-ext-zip {fill: #444444}
  &-ext-jpg {fill: #ff8a00}

  @include when-inside("footer") {
    fill: map-get($color-map, merlin);
    width: 4rem;
    height: 4rem;

    &:hover {
      fill: map-get($color-map, gray);
    }
  }

  @include when-inside("overlay", "--admin") {
    width: 6rem;
    height: 6rem;
    fill: map-get($color-map, gray);
    z-index: 40;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
  }

  @include when-inside("button", "-icontext") {
    margin-right: 0.5rem;
  }

  &-profile {
    fill: white;
  }

  &-info-with-circle {
    width: 12px;
    height: 12px;
  }
  
}
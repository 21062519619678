.logo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem;
  background: darken(map-get($map: $color-map, $key: secondary), 3%);

  &__img {
    max-height: 5rem;
    max-width: 5rem;
    margin:0 1rem 0 0;
    border: 3px solid map-get($color-map, white);
    border-radius: 25px;  
  }

  &__text {
    @extend %size-medium;
    white-space: nowrap;
    color: map-get($color-map, white);

    &-sub {
      font-size: 60%;
      display: block;
      text-align: center;
    }
  }

}
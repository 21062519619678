.navigation {
  $navigation-root: &;

  width: 100%;
  flex: 1;
  overflow: auto;

  &__wrap {
    list-style: none;
    position: relative;
    z-index: 999;
    transition: opacity 200ms, left 200ms;
    transform: none;

    &--visible {
      opacity: 1;
      height: auto;
      overflow: visible;
    }

    &--firstLevel {
      overflow: auto;
    }

    // Sottolivelli
    &--secondLevel,
    &--thirdLevel {
      width: 100%;
      opacity: 0;
      height: 0;
      overflow: hidden;
      position: static;
      box-shadow: none;

      &#{$navigation-root}__wrap--visible {
        opacity: 1;
        height: auto;
        overflow: visible;
      }

    }

    &--secondLevel {
      background: darken(map-get($map: $color-map, $key: secondary), 6%);
    }

    // secondo livello
    &--thirdLevel {
      background: darken(map-get($map: $color-map, $key: secondary), 10%);
    }
  
  }

  &__item {
    font-family: map-deep-get($family, secondary);
    border-top: 1px solid darken(map-get($map: $color-map, $key: secondary), 8%);
    text-transform: uppercase;
    background: transparent;
    position: relative;
    margin: 0;

    #{$navigation-root}__wrap--firstLevel > &:last-child {
      border: 0
    }
  }

  // Ancore
  &__anchor {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 1rem;

    &:link,
    &:visited {
      color: map-get($color-map, white);
    }

    #{$navigation-root}__wrap--firstLevel > .navigation__item > & {
      @extend %size-small;
    }

    // Secondo livello
    #{$navigation-root}__wrap--secondLevel > .navigation__item > & {

      @extend %size-small;

      &:hover,
      &:active {
        background: darken(map-get($map: $color-map, $key: secondary), 5%);
      }
    }

    // Secondo livello
    #{$navigation-root}__wrap--thirdLevel > .navigation__item > & {
      
      @extend %size-small;

      &:hover,
      &:active {
        background: darken(map-get($map: $color-map, $key: secondary), 7%);
      }
    }

    // variante con freccia
    &--arrow {
      position: relative;
      padding-right: 1.5rem;

      // Arrow down
      svg:last-child {
        margin-left: auto;

        #{$navigation-root}__anchor--arrow & {
          transform: rotate(-180deg);
        }
      }
    }
  }

}
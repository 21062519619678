@charset "UTF-8";
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 50%;
}

@media (min-width: 520px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 62.5%;
  }
}

html,
body {
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262626;
  min-height: 100vh;
  padding: 0;
  margin: 0 !important;
  background: #f7f7f7;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

hr {
  border-color: #293F52;
}

.footer hr {
  border-color: #3D3D38;
}

a:link,
a:visited {
  color: #262626;
  text-decoration: none;
  transition: all 250ms;
}

a:hover,
a:active {
  color: black;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  appearance: none;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  outline: 0;
}

label,
legend {
  display: block;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

.size-big,
h1 {
  font-size: 4rem;
}

.size-large,
h2,
blockquote {
  font-size: 3rem;
}

.size-medium,
h3,
.logo__text {
  font-size: 1.8rem;
}

.size-base,
body,
h4,
.footer__title,
.user__text {
  font-size: 1.6rem;
  font-weight: 400;
}

.size-small,
.navigation__wrap--firstLevel > .navigation__item > .navigation__anchor,
.navigation__wrap--secondLevel > .navigation__item > .navigation__anchor,
.navigation__wrap--thirdLevel > .navigation__item > .navigation__anchor,
.form-control,
.angucomplete-row,
.popover-title {
  font-size: 1.4rem;
  font-weight: 400;
}

.size-micro,
.form-helper-text,
.form-label {
  font-size: 1.2rem;
}

.color-primary {
  color: #262626;
}

.bg-primary {
  background-color: #262626;
}

.color-secondary {
  color: #293F52;
}

.bg-secondary {
  background-color: #293F52;
}

.color-tertiary {
  color: #00BD9F;
}

.bg-tertiary {
  background-color: #00BD9F;
}

.color-white {
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

.color-black {
  color: #000000;
}

.bg-black {
  background-color: #000000;
}

.color-error {
  color: #ff005c;
}

.bg-error {
  background-color: #ff005c;
}

.color-alabaster-light {
  color: #fbfbfb;
}

.bg-alabaster-light {
  background-color: #fbfbfb;
}

.color-alabaster {
  color: #f7f7f7;
}

.bg-alabaster {
  background-color: #f7f7f7;
}

.color-mercury {
  color: #e8e8e8;
}

.bg-mercury {
  background-color: #e8e8e8;
}

.color-gray {
  color: #868686;
}

.bg-gray {
  background-color: #868686;
}

.color-gallery {
  color: #efefef;
}

.bg-gallery {
  background-color: #efefef;
}

.color-merlin {
  color: #3D3D38;
}

.bg-merlin {
  background-color: #3D3D38;
}

.color-carnation {
  color: #F95C69;
}

.bg-carnation {
  background-color: #F95C69;
}

.color-buttercup {
  color: #efac1a;
}

.bg-buttercup {
  background-color: #efac1a;
}

.color-apple {
  color: #469b3f;
}

.bg-apple {
  background-color: #469b3f;
}

.color-paypal {
  color: #003087;
}

.bg-paypal {
  background-color: #003087;
}

.color-facebook {
  color: #3b5998;
}

.bg-facebook {
  background-color: #3b5998;
}

.color-twitter {
  color: #55acee;
}

.bg-twitter {
  background-color: #55acee;
}

.color-pinterest {
  color: #cc2127;
}

.bg-pinterest {
  background-color: #cc2127;
}

.livedit {
  position: relative;
}

.debug-off pre {
  display: none;
}

/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.row + .row {
  margin-top: 15px;
}

.row [class*="col-"] + [class*="col-"] {
  margin-top: 15px;
}

@media (min-width: 1200px) {
  .row [class*="col-lg-"] + [class*="col-lg-"] {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .row [class*="col-md-"] + [class*="col-md-"] {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .row [class*="col-sm-"] + [class*="col-sm-"] {
    margin-top: 0;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.grid--gutters {
  margin: -1rem;
}

.grid--gutters > * {
  margin: 1rem;
}

.grid--flexCells > * {
  display: flex;
}

.grid--top {
  align-items: flex-start;
}

.grid--bottom {
  align-items: flex-end;
}

.grid--center {
  align-items: center;
}

.grid--justifyCenter {
  justify-content: center;
}

.grid-cell--top {
  align-self: flex-start;
}

.grid-cell--bottom {
  align-self: flex-end;
}

.grid-cell--center {
  align-self: center;
}

.grid-cell--autoSize {
  flex: none;
}

.grid--fit > * {
  flex: 1;
}

.grid--full > * {
  flex: 0 0 calc(100% - 1rem * 2);
}

.grid--1of2 > * {
  flex: 0 0 calc(50% - 1rem * 2);
}

.grid--1of3 > * {
  flex: 0 0 calc(33.3333% - 1rem * 2);
}

.grid--1of4 > * {
  flex: 0 0 calc(25% - 1rem * 2);
}

.grid--1of6 > * {
  flex: 0 0 calc(16.6666667% - 1rem * 2);
}

@media (min-width: 520px) {
  .small-grid--fit > * {
    flex: 1;
  }
  .small-grid--full > * {
    flex: 0 0 calc(100% - 1rem * 2);
  }
  .small-grid--1of2 > * {
    flex: 0 0 calc(50% - 1rem * 2);
  }
  .small-grid--1of3 > * {
    flex: 0 0 calc(33.3333% - 1rem * 2);
  }
  .small-grid--1of4 > * {
    flex: 0 0 calc(25% - 1rem * 2);
  }
  .small-grid--1of6 > * {
    flex: 0 0 calc(33.3333% - 1rem * 2);
  }
}

@media (min-width: 768px) {
  .med-grid--fit > * {
    flex: 1;
  }
  .med-grid--full > * {
    flex: 0 0 calc(100% - 1rem * 2);
  }
  .med-grid--1of2 > * {
    flex: 0 0 calc(50% - 1rem * 2);
  }
  .med-grid--1of3 > * {
    flex: 0 0 calc(33.3333% - 1rem * 2);
  }
  .med-grid--1of4 > * {
    flex: 0 0 calc(25% - 1rem * 2);
  }
  .med-grid--1of6 > * {
    flex: 0 0 calc(33.3333% - 1rem * 2);
  }
}

@media (min-width: 992px) {
  .large-grid--fit > * {
    flex: 1;
  }
  .large-grid--full > * {
    flex: 0 0 calc(100% - 1rem * 2);
  }
  .large-grid--1of2 > * {
    flex: 0 0 calc(50% - 1rem * 2);
  }
  .large-grid--1of3 > * {
    flex: 0 0 calc(33.3333% - 1rem * 2);
  }
  .large-grid--1of4 > * {
    flex: 0 0 calc(25% - 1rem * 2);
  }
  .large-grid--1of6 > * {
    flex: 0 0 calc(16.6666667% - 1rem * 2);
  }
}

body {
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  font-family: "Raleway", sans-serif;
}

h3 {
  font-weight: 700;
}

h4 {
  font-weight: 700;
}

p {
  margin: 1.5rem 0;
  line-height: 1.5;
}

p:last-child {
  margin-bottom: 0;
}

ul li {
  margin: 1.5rem 0;
}

blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
  font-weight: 300;
}

blockquote:before, blockquote:after {
  font-size: 250%;
  line-height: 0.1em;
  vertical-align: -0.4em;
}

blockquote:before {
  content: open-quote;
  margin-right: 2.5rem;
}

blockquote:after {
  content: close-quote;
  margin-left: 0;
}

.text--author strong:after {
  content: ' - ';
}

.text--price > * {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin: 0 0.5rem 0 0;
}

.wrap {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
}

.wrap__main {
  flex: 1;
}

@media (min-width: 768px) {
  .wrap__main {
    width: calc(100% - 200px);
  }
}

.wrap__top {
  background: #ededed;
  padding: 1rem 1rem 1rem 5rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  height: 71px;
  align-items: center;
  justify-content: space-between;
}

.wrap__content {
  padding: 1.5rem;
  background: #f7f7f7;
}

.header {
  margin-left: 0;
  transition: margin-left 250ms;
  display: flex;
  flex-direction: column;
  background: #293F52;
  flex: 1 0 calc(100% - 30px);
  width: calc(100% - 30px);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

@media (min-width: 768px) {
  .header {
    width: auto;
    height: auto;
    position: relative;
    flex: 0 0 200px;
  }
}

.header--hidden {
  margin-left: calc(-100% + 30px);
}

@media (min-width: 768px) {
  .header--hidden {
    margin-left: -200px;
  }
}

.header__ham {
  position: absolute;
  border-radius: 4px;
  display: block;
  right: -3.3rem;
  height: 3rem;
  width: 3rem;
  padding: 0.3rem;
  line-height: normal;
  cursor: pointer;
  top: 0;
}

.header__ham-bar {
  display: block;
  height: 2px;
  border-radius: 1px;
  background-color: #10181f;
  width: 2.2rem;
  margin: 0.4rem auto 0;
}

.navigation {
  width: 100%;
  flex: 1;
  overflow: auto;
}

.navigation__wrap {
  list-style: none;
  position: relative;
  z-index: 999;
  transition: opacity 200ms, left 200ms;
  transform: none;
}

.navigation__wrap--visible {
  opacity: 1;
  height: auto;
  overflow: visible;
}

.navigation__wrap--firstLevel {
  overflow: auto;
}

.navigation__wrap--secondLevel, .navigation__wrap--thirdLevel {
  width: 100%;
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: static;
  box-shadow: none;
}

.navigation__wrap--secondLevel.navigation__wrap--visible, .navigation__wrap--thirdLevel.navigation__wrap--visible {
  opacity: 1;
  height: auto;
  overflow: visible;
}

.navigation__wrap--secondLevel {
  background: #1f2f3e;
}

.navigation__wrap--thirdLevel {
  background: #182530;
}

.navigation__item {
  font-family: "Raleway", sans-serif;
  border-top: 1px solid #1b2a37;
  text-transform: uppercase;
  background: transparent;
  position: relative;
  margin: 0;
}

.navigation__wrap--firstLevel > .navigation__item:last-child {
  border: 0;
}

.navigation__anchor {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 1rem;
}

.navigation__anchor:link, .navigation__anchor:visited {
  color: #ffffff;
}

.navigation__wrap--secondLevel > .navigation__item > .navigation__anchor:hover, .navigation__wrap--secondLevel > .navigation__item > .navigation__anchor:active {
  background: #213241;
}

.navigation__wrap--thirdLevel > .navigation__item > .navigation__anchor:hover, .navigation__wrap--thirdLevel > .navigation__item > .navigation__anchor:active {
  background: #1d2d3a;
}

.navigation__anchor--arrow {
  position: relative;
  padding-right: 1.5rem;
}

.navigation__anchor--arrow svg:last-child {
  margin-left: auto;
}

.navigation__anchor--arrow .navigation__anchor--arrow svg:last-child {
  transform: rotate(-180deg);
}

.form-group, .form-group-wrapper {
  margin-bottom: 15px;
  position: relative;
}

.form-group:last-child, .form-group-wrapper:last-child {
  margin-bottom: 0;
}

.form-helper-text {
  position: absolute;
  right: 5px;
  top: 100%;
  color: #ff005c;
  text-transform: uppercase;
}

.form-helper-text span {
  margin-left: 10px;
}

.form-icon-help {
  display: inline-flex;
  vertical-align: middle;
}

.form-control {
  display: block;
  width: 100%;
  height: 28px;
  padding: 0px;
  background-image: none;
  background-color: #ffffff;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  color: #262626;
}

.form-group.has-error .form-control {
  border-color: #ff005c;
}

.form-control--inline {
  display: inline-block;
  width: auto;
}

.form-control[disabled], .form-control[readonly] {
  cursor: no-drop;
}

.form-control-select {
  border-radius: 0;
  padding-right: 25px;
  background: #fff url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'><path d='M13.418 7.859c0.271-0.268 0.709-0.268 0.978 0s0.272 0.701 0 0.969l-3.908 3.83c-0.27 0.268-0.707 0.268-0.979 0l-3.908-3.83c-0.27-0.267-0.27-0.701 0-0.969s0.709-0.268 0.978 0l3.421 3.141 3.418-3.141z'></path></svg>") no-repeat scroll calc(100% - 5px) center;
}

.form-control-select[disabled] {
  opacity: .5;
  cursor: no-drop;
}

.form-control-area {
  height: 100px;
  padding: 4px;
  background: #f7f7f7;
}

.form-label {
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 5px;
}

.form-group.has-error .form-label {
  color: #ff005c;
}

.footer {
  color: #ffffff;
  margin-top: auto;
}

.footer a:link,
.footer a:visited {
  color: #ffffff;
}

.footer__top {
  padding: 1rem;
  background: #00947d;
  text-align: center;
}

.footer__title {
  font-weight: 700;
}

.footer__bottom {
  padding: 1rem;
  background: #00BD9F;
}

.listing {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
}

@media (min-width: 768px) {
  .listing {
    flex-direction: row;
    align-items: stretch;
    overflow: visible;
  }
}

.listing h2 {
  display: none;
}

.listing__filterAction {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.listing__filterAction svg {
  margin-right: 5px;
}

@media (min-width: 768px) {
  .listing__filterAction {
    display: none;
  }
}

.listing__sidebar {
  background: #ffffff;
  position: absolute;
  right: -100%;
  top: 24px;
  z-index: 9;
  transition: right 300ms;
}

.listing__sidebar--show {
  right: -3px;
}

@media (min-width: 768px) {
  .listing__sidebar {
    position: static;
    flex: 0 0 25%;
    margin-right: 30px;
    display: flex;
  }
}

.listing__products {
  flex: 1;
  transition: filter 300ms;
}

.listing__products--blurred {
  filter: blur(2px);
}

@media (min-width: 768px) {
  .listing__products--blurred {
    filter: none;
  }
}

.listing__products--blurred:after {
  content: '';
  z-index: 1;
  position: fixed;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (min-width: 768px) {
  .listing__products--blurred:after {
    display: none;
  }
}

.angucomplete-holder {
  position: relative;
}

.angucomplete-dropdown {
  border-color: #ececec;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  width: 250px;
  padding: 6px;
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  /*top: 32px;
  left: 0px;
  */
  margin-top: -6px;
  background-color: #ffffff;
}

.angucomplete-searching {
  color: #acacac;
  font-size: 14px;
}

.angucomplete-description {
  font-size: 14px;
}

.angucomplete-row {
  padding: 5px;
  color: #000000;
  margin-bottom: 4px;
  clear: both;
}

.angucomplete-selected-row {
  background-color: lightblue;
  color: #ffffff;
}

.angucomplete-image-holder {
  padding-top: 2px;
  float: left;
  margin-right: 10px;
  margin-left: 5px;
}

.angucomplete-image {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border-color: #ececec;
  border-style: solid;
  border-width: 1px;
}

.angucomplete-image-default {
  /* Add your own default image here
   background-image: url('/assets/default.png');
  */
  background-position: center;
  background-size: contain;
  height: 34px;
  width: 34px;
}

.angucomplete-dropdown {
  width: 100%;
  padding: 0px;
  margin-top: 0;
  max-height: 200px;
  overflow: auto;
}

.angucomplete-row {
  padding: 4px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
}

.angucomplete-selected-row {
  background-color: #293F52;
}

.angucomplete-description {
  font-weight: 700;
}

.logo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem;
  background: #243748;
}

.logo__img {
  max-height: 5rem;
  max-width: 5rem;
  margin: 0 1rem 0 0;
  border: 3px solid #ffffff;
  border-radius: 25px;
}

.logo__text {
  white-space: nowrap;
  color: #ffffff;
}

.logo__text-sub {
  font-size: 60%;
  display: block;
  text-align: center;
}

.popover {
  position: absolute !important;
  max-width: 15rem;
  background-color: #fff;
  border: 1px solid rgba(38, 38, 38, 0.25);
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 99999;
}

.popover a:link,
.popover a:visited {
  color: inherit;
}

.popover-title {
  padding: 0.8rem 1.5rem;
  margin: 0;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 3px 3px 0 0;
}

.popover-content {
  color: #868686;
  text-align: left;
  padding: 10px;
}

.popover.right {
  margin-left: 1rem;
}

.popover.left {
  margin-left: -1rem;
}

.popover.bottom {
  margin-top: 1rem;
}

.popover.top {
  margin-top: -1rem;
}

.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow:after {
  border-width: 10px;
  content: "";
}

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}

.popover.top > .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.right > .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}

.popover.bottom > .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left > .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}

.button {
  display: inline-block;
  background-color: #efefef;
  zoom: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Raleway", sans-serif;
  font-size: 100%;
  border: 1px solid transparent;
  padding: .5em 1em;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 1.5rem;
  transition: opacity 200ms;
}

@media (min-width: 768px) {
  .button {
    margin-top: 0;
  }
}

.button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.button-block {
  width: 100%;
}

.button-icontext {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button:focus, .button:hover {
  opacity: 0.8;
}

.button:active {
  border-color: #000000;
}

.button[disabled] {
  border: none;
  background-image: none;
  filter: alpha(opacity=40);
  opacity: .3;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
}

.button-transparent {
  background: transparent;
  border: 0;
}

.button-outline, .button-outline-light, .button-outline-light:link, .button-outline-light:visited, .button-outline-dark, .button-outline-dark:link, .button-outline-dark:visited {
  border: 1px solid;
  background: transparent !important;
}

.button-outline-light, .button-outline-light:link, .button-outline-light:visited {
  border-color: #ffffff;
  color: #ffffff;
}

.button-outline-dark, .button-outline-dark:link, .button-outline-dark:visited {
  border-color: #000000;
  color: #000000;
}

.button-primary, .button-success, .button-error, .button-warning, .button-secondary, .button-paypal, .button-facebook, .button-twitter, .button-pinterest {
  color: #ffffff !important;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-primary {
  background: #262626;
}

.button-secondary {
  background: #293F52;
}

.button-success {
  background: #469b3f;
}

.button-error {
  background: #F95C69;
}

.button-warning {
  background: #efac1a;
}

.button-paypal {
  background: #003087;
}

.button-facebook {
  background: #3b5998;
}

.button-twitter {
  background: #55acee;
}

.button-pinterest {
  background: #cc2127;
}

.button-xxsmall {
  font-size: 50%;
  font-weight: 700;
}

.button-xsmall {
  font-size: 70%;
  padding: 3px;
  font-weight: 700;
}

.button-small {
  font-size: 85%;
  padding: 3px;
  font-weight: 700;
}

.button-normal {
  font-size: 100%;
}

.button-large {
  font-size: 110%;
}

.button-xlarge {
  font-size: 125%;
}

.breadcrumb {
  padding: 0.5rem 1.2rem;
  background-color: #ffffff;
  color: #868686;
}

.breadcrumb__item {
  display: inline-block;
  text-transform: uppercase;
}

.breadcrumb__item a:link,
.breadcrumb__item a:visited {
  text-decoration: underline;
  color: #868686;
}

.breadcrumb__item + li:before {
  content: "/\00a0";
  padding: 0 0.5rem;
}

.table {
  background-color: white;
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 5px 2px 5px 2px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: 1px solid #efefef;
}

.table > thead > tr > th {
  vertical-align: middle;
  border: 1px solid #00BD9F;
  border-bottom: 3px solid #00BD9F;
  background: #00947d;
  padding: 4px;
  color: white;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #efefef;
}

.table-bordered {
  border: 1px solid #efefef;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}

@media (min-width: 768px) {
  .table-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.8);
}

.table-striped tbody tr:nth-of-type(even):hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.icon {
  width: 20px;
  height: 20px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  transition: fill 250ms;
}

.icon[class|="icon icon-ext"] {
  width: 32px;
  height: 32px;
}

.icon-ext-xls {
  fill: #267446;
}

.icon-ext-doc {
  fill: #375595;
}

.icon-ext-pdf {
  fill: #E9353C;
}

.icon-ext-zip {
  fill: #444444;
}

.icon-ext-jpg {
  fill: #ff8a00;
}

.footer .icon {
  fill: #3D3D38;
  width: 4rem;
  height: 4rem;
}

.footer .icon:hover {
  fill: #868686;
}

.overlay--admin .icon {
  width: 6rem;
  height: 6rem;
  fill: #868686;
  z-index: 40;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
}

.button-icontext .icon {
  margin-right: 0.5rem;
}

.icon-profile {
  fill: white;
}

.icon-info-with-circle {
  width: 12px;
  height: 12px;
}

.box {
  border-radius: 4px;
  padding: 15px;
  overflow: auto;
}

.box .box {
  padding: 1rem;
  border-bottom: 1px solid #efefef;
}

.box--squared {
  border: 1px solid #efefef;
}

.box--simple {
  background: #ffffff;
}

.box--outlined {
  border: 2px solid #00BD9F;
  background: #ffffff;
}

.listing__sidebar .box {
  flex: 1;
}

.tag {
  font-weight: 700;
  padding: 1px 4px;
  border-radius: 4px;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.tag--dark {
  background: #262626;
  color: #ffffff;
}

.tag--light {
  background: #efefef;
  color: #262626;
}

.tag--danger {
  background: #F95C69;
  color: #ffffff;
}

.tag--warning {
  background: #efac1a;
  color: #262626;
}

.tag--success {
  background: #469b3f;
  color: #ffffff;
}

.collection__figure .tag {
  position: absolute;
  right: 15px;
  top: 5px;
  padding-right: 1px;
  padding-left: 15px;
}

.collection__figure .tag:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #ececec;
  position: absolute;
  left: 4px;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 100%;
}

.collection__figure .tag:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10.3px;
  border-color: transparent transparent transparent #000000;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -1px;
}

.tab {
  margin: 50px;
}

.tab__nav {
  list-style: none;
  display: flex;
  border-bottom: 1px solid #efefef;
}

.tab__nav-item {
  margin: 0 0 -1px 1rem;
}

.tab__nav-anchor {
  position: relative;
  display: block;
  padding: 1rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 3px 3px 0 0;
}

.tab__nav-item--active .tab__nav-anchor {
  color: #868686;
  cursor: default;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-bottom-color: transparent;
}

.tab__panel {
  padding: 1.5rem;
}

.tab__panel:not(.tab__panel--active) {
  display: none;
}

.badge {
  width: 17px;
  height: 17px;
  background-color: #ff005c;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  justify-content: center;
  border: 1px solid #990037;
  position: absolute;
  top: 3px;
  right: 3px;
}

.user {
  display: flex;
  align-items: center;
}

.user__img {
  max-height: 4rem;
  max-width: 4rem;
  margin: 0 1rem 0 0;
  border: 3px solid #293F52;
  border-radius: 25px;
}

.user__text {
  white-space: nowrap;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.align-right {
  text-align: right !important;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.display-inlineblock {
  display: inline-block !important;
}

.transform-uppercase {
  text-transform: uppercase !important;
}

.transform-bold {
  font-weight: bold !important;
}

.transform-italic {
  font-style: italic !important;
}

.transform-through {
  text-decoration: line-through !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.clearfix:after,
.livedit:after {
  content: "";
  display: table;
  clear: both;
}

.u-border-top {
  border-top: 1px solid #efefef;
}

.u-padding-vertical-small {
  padding: 1.5rem 0 !important;
}

.u-padding-vertical-medium {
  padding: 3rem 0 !important;
}

.u-padding-vertical-big {
  padding: 8rem 0 !important;
}

.u-padding-top-none {
  padding-top: 0 !important;
}

.u-margin-none {
  margin: 0 !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-margin-small {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-bottom-micro {
  margin-bottom: 0.5rem !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 3rem !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-top-small {
  margin-top: 1.5rem !important;
}

.u-margin-top-medium {
  margin-top: 3rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

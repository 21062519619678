.box {
  border-radius: 4px;
  padding: 15px; // per compensare i margini di eventuali row di bootstrap (-15px) contenute all'interno
  overflow: auto;

  // box nested
  & & {
    padding: 1rem;
    border-bottom: 1px solid map-deep-get($color-map, gallery);
  }

  &--squared {
    border: 1px solid map-deep-get($color-map, gallery);
  }

  &--simple {
    background: map-deep-get($color-map, white);
  }


  &--outlined {
    border: 2px solid map-deep-get($color-map, tertiary);
    background: map-deep-get($color-map, white);
  }

  @at-root .listing__sidebar & {
    flex: 1;
  }
  
}
// Colors Map
// Thanks to http://chir.ag/projects/name-that-color

$color-map: (
  // main colors
  primary:    #262626,
  secondary:  #293F52,
  tertiary:   #00BD9F,

  // Standard
  white:    #ffffff,
  black:    #000000,
  error:    #ff005c,

  // Gray
  alabaster-light:#fbfbfb,
  alabaster:        #f7f7f7,
  mercury:  #e8e8e8,
  gray:     #868686,
  gallery:  #efefef,
  merlin:   #3D3D38,

  // Others
  carnation:  #F95C69,
  buttercup:  #efac1a,
  apple:      #469b3f,
  paypal:     #003087,
  facebook:   #3b5998,
  twitter:    #55acee,
  pinterest:  #cc2127
);
.badge {
  width: 17px;
  height: 17px;
  background-color: map-get($color-map, error);
  color: map-get($color-map, white);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  justify-content: center;
  border: 1px solid darken(map-get($color-map, error), 20%);
  position: absolute;
  top: 3px;
  right: 3px;
}
// BEM Mappa macroaree
$rootarea: (
  'page': '.page',
  'header': '.header',
  'accordion': '.accordion',
  'collection': '.collection',
  'footer': '.footer',
  'overlay': '.overlay',
  'button': '.button'
);

// Area - Header
$header-width-mb: 100%;
$header-width-dk: 200px;
.breadcrumb {
  padding: 0.5rem 1.2rem;
  background-color: map-get($color-map, white);
  color: map-get($color-map, gray);

  &__item {
    display: inline-block;
    text-transform: uppercase;

    a:link,
    a:visited {
      text-decoration: underline;
      color: map-get($color-map, gray);
    }

    + li:before {
      content: "/\00a0";
      padding: 0 0.5rem;
    }

  }
}


body {
  @extend %size-base;
  font-family: map-deep-get($family, primary);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  font-family: map-deep-get($family, secondary);
}

h1  {@extend %size-big;}
h2  {@extend %size-large;}
h3  {@extend %size-medium;font-weight: 700;}
h4  {@extend %size-base;font-weight: 700;}

p {
  margin: 1.5rem 0;
  line-height: 1.5;

  &:last-child {
    margin-bottom: 0;
  }
}

ul li {
  margin: 1.5rem 0;
}

blockquote {
  @extend %size-large;
  quotes: "\201C""\201D""\2018""\2019";
  font-style: italic;
  font-weight: 300;

  &:before,
  &:after {
    font-size: 250%;
    line-height: 0.1em;
    vertical-align: -0.4em;
  }

  &:before {
    content: open-quote;
    margin-right: 2.5rem;
  }

  &:after {
    content: close-quote;
    margin-left: 0;
  }
}

// todo
.text {

  // Author
  &--author {
    strong:after {
      content: ' - ';
    }
  }

  &--price { 
    & > * {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      margin: 0 0.5rem 0 0;
    }
  }

}
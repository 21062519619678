.button {

  // Defaults
  display: inline-block;
  background-color: map-get($color-map, gallery);
  zoom:1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: map-deep-get($family, secondary);
  font-size: 100%;
  border: 1px solid transparent;
  padding: .5em 1em;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 1.5rem;
  transition: opacity 200ms;

  @include respond-to('s-768') {
    margin-top: 0;
  }

  &::-moz-focus-inner { // moz fix
    padding: 0;
    border: 0
  }

  // others
  &-block {width: 100%}

  &-icontext {
    display: inline-flex;
    justify-content: center;
    align-items: center
  }

  // Status
  &:focus,
  &:hover {
    opacity: 0.8;
  }

  &:active {
    border-color: map-get($color-map, black);
  }

  &[disabled] {
    border: none;
    background-image: none;
    filter: alpha(opacity=40);
    opacity: .3;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none
  }

  &-transparent {
    background: transparent;
    border: 0;
  }

  &-outline {
    border: 1px solid;
    background: transparent!important;

    &-light,
    &-light:link,
    &-light:visited {
      @extend .button-outline;
      border-color: map-get($color-map, white);
      color: map-get($color-map, white);
    }

    &-dark,
    &-dark:link,
    &-dark:visited {
      @extend .button-outline;
      border-color: map-get($color-map, black);
      color: map-get($color-map, black);
    }
  }

  &-primary,
  &-success,
  &-error,
  &-warning,
  &-secondary,
  &-paypal,
  &-facebook,
  &-twitter,
  &-pinterest {
    color: map-get($color-map, white)!important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }

  // Styles
  &-primary   {background: map-get($color-map, primary)}
  &-secondary {background: map-get($color-map, secondary)}
  &-success   {background: map-get($color-map, apple)}
  &-error     {background: map-get($color-map, carnation)}
  &-warning   {background: map-get($color-map, buttercup)}
  &-paypal    {background: map-get($color-map, paypal)}
  &-facebook  {background: map-get($color-map, facebook)}
  &-twitter   {background: map-get($color-map, twitter)}
  &-pinterest {background: map-get($color-map, pinterest)}

  // Size
  &-xxsmall {font-size: 50%; font-weight: 700;}
  &-xsmall  {font-size: 70%; padding: 3px; font-weight: 700;}
  &-small   {font-size: 85%; padding: 3px; font-weight: 700;}
  &-normal  {font-size: 100%}
  &-large   {font-size: 110%}
  &-xlarge  {font-size: 125%}

}












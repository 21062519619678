.header {
  $topbar-root: &;

  margin-left: 0;
  transition: margin-left 250ms;
  display: flex;
  flex-direction: column;
  background: map-get($color-map, secondary);
  flex: 1 0 calc(#{$header-width-mb} - 30px);
  width: calc(#{$header-width-mb} - 30px);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  @include respond-to("s-768") {
    width: auto;
    height: auto;
    position: relative;
    flex: 0 0 $header-width-dk;
  }

  &--hidden {
    margin-left: calc(-#{$header-width-mb} + 30px);

    @include respond-to("s-768") {
      margin-left: -$header-width-dk;
    }
  }
  
  // hamburger
  &__ham {
    position: absolute;
    border-radius: 4px;
    display: block;
    right: -3.3rem;
    height: 3rem;
    width: 3rem;
    padding: 0.3rem;
    line-height: normal;
    cursor: pointer;
    top: 0;

    &-bar {
      display: block;
      height: 2px;
      border-radius: 1px;
      background-color: darken(map-get($map: $color-map, $key: secondary), 15%);
      width: 2.2rem;
      margin: 0.4rem auto 0;
    }
  }

}
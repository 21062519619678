.popover {
  position: absolute!important;
  max-width: 15rem;
  background-color: #fff;
  border: 1px solid rgba(map-get($color-map, primary), .25);
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  z-index: 99999;

  a:link,
  a:visited {
    color: inherit;
  }

  

  &-title {
    @extend %size-small;
    padding: 0.8rem 1.5rem;
    margin: 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 3px 3px 0 0;
  }

  &-content {
    color: map_get($color-map, gray);
    text-align: left;
    padding: 10px;
  }

  // positioning
  &.right {
    margin-left: 1rem;
  }

  &.left {
    margin-left: -1rem;
  }

  &.bottom {
    margin-top: 1rem;
  }

  &.top {
    margin-top: -1rem;
  }


}

// todo :: sistemare in sass questa parte
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow:after {
  border-width: 10px;
  content: "";
}
.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}
.popover.top > .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.right > .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}
.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}
.popover.bottom > .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left > .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}

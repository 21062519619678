.footer {
  $root: &;

  // Common
  color: map-get($color-map, white);
  margin-top: auto;

  a:link,
  a:visited {
    color: map-get($color-map, white);
  }

  // parte alta
  &__top {
    padding: 1rem;
    background: darken(map-get($color-map, tertiary), 8%);
    text-align: center
  }

  &__title {
    @extend %size-base;
    font-weight: 700;
  }

  // parte bassa
  &__bottom {
    padding: 1rem;
    background: map-get($color-map, tertiary);
  }

}
.table {
  background-color: white;
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 5px 2px 5px 2px;
    line-height: 1.42857143;
    vertical-align: middle;
    border-top: 1px solid map-get($color-map, gallery);
  }

  > thead > tr > th {
    vertical-align: middle;
    border: 1px solid map-get($color-map, tertiary);
    border-bottom: 3px solid map-get($color-map, tertiary);
    background: darken(map-get($color-map, tertiary), 8%);
    padding: 4px;
    color: white;
  }

  > caption + thead > tr:first-child > th,
  > colgroup + thead > tr:first-child > th,
  > thead:first-child > tr:first-child > th,
  > caption + thead > tr:first-child > td,
  > colgroup + thead > tr:first-child > td,
  > thead:first-child > tr:first-child > td {
    border-top: 0;
  }
  > tbody + tbody {
    border-top: 2px solid map-get($color-map, gallery);
  }

  &-bordered {
    border: 1px solid map-get($color-map, gallery);

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      
    }
    > thead > tr > th,
    > thead > tr > td {
      
    }

  }

  &-responsive {
    overflow-x: auto;
    min-height: 0.01%;
    //margin-bottom: 15px;

    @include respond-to("s-768") {
      width: 100%;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;


      > .table > thead > tr > th,
      > .table > tbody > tr > th,
      > .table > tfoot > tr > th,
      > .table > thead > tr > td,
      > .table > tbody > tr > td,
      > .table > tfoot > tr > td {
        white-space: nowrap;
      }
      > .table-bordered {
        border: 0;
      }
      > .table-bordered > thead > tr > th:first-child,
      > .table-bordered > tbody > tr > th:first-child,
      > .table-bordered > tfoot > tr > th:first-child,
      > .table-bordered > thead > tr > td:first-child,
      > .table-bordered > tbody > tr > td:first-child,
      > .table-bordered > tfoot > tr > td:first-child {
        border-left: 0;
      }
      > .table-bordered > thead > tr > th:last-child,
      > .table-bordered > tbody > tr > th:last-child,
      > .table-bordered > tfoot > tr > th:last-child,
      > .table-bordered > thead > tr > td:last-child,
      > .table-bordered > tbody > tr > td:last-child,
      > .table-bordered > tfoot > tr > td:last-child {
        border-right: 0;
      }
      > .table-bordered > tbody > tr:last-child > th,
      > .table-bordered > tfoot > tr:last-child > th,
      > .table-bordered > tbody > tr:last-child > td,
      > .table-bordered > tfoot > tr:last-child > td {
        border-bottom: 0;
      }

    }
  }

  &-striped {
    tbody tr:nth-of-type(even) {
      background-color: rgba(255,255,255,.8);

      &:hover {
        background-color: rgba(0,0,0,.1);
      }
    }
    tbody tr:nth-of-type(odd) {
      background-color: rgba(0,0,0,.05);

      &:hover {
        background-color: rgba(0,0,0,.1);
      }
    }
  }

}
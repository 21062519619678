.form {
  $form-root: &;

  &-group,
  &-group-wrapper {
    margin-bottom: 15px;
    position: relative;

    &:last-child {
      margin-bottom: 0
    }
  }

  &-helper-text {
    @extend %size-micro;
    position: absolute;
    right: 5px;
    top: 100%;
    color: map-get($color-map, error);
    text-transform: uppercase;

    span {
      margin-left: 10px
    }
  }

  &-icon-help {
    display: inline-flex;
    vertical-align: middle;
  }

  &-control {
    display: block;
    width: 100%;
    height: 28px;
    padding: 0px;
    background-image: none;
    background-color: map-get($color-map, white);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;

    @extend %size-small;
    border: 0;
    border-bottom: 1px solid map-get($color-map, mercury);
    color: map-get($color-map, primary);
    
    #{$form-root}-group.has-error & {
      border-color: map-get($color-map, error);
    }

    // Variante inline
    &--inline {
      display: inline-block;
      width: auto;
    }

    &[disabled],
    &[readonly] {
      cursor: no-drop;
    }

    // variante select
    &-select {
      border-radius: 0;
      padding-right: 25px;
      background: #fff url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'><path d='M13.418 7.859c0.271-0.268 0.709-0.268 0.978 0s0.272 0.701 0 0.969l-3.908 3.83c-0.27 0.268-0.707 0.268-0.979 0l-3.908-3.83c-0.27-0.267-0.27-0.701 0-0.969s0.709-0.268 0.978 0l3.421 3.141 3.418-3.141z'></path></svg>") no-repeat scroll calc(100% - 5px) center;

      &[disabled] {
        opacity: .5;
        cursor: no-drop;
      }
    }

    // Variante textarea
    &-area {
      height: 100px;
      padding: 4px;
      background: map-get($color-map, alabaster);
    }

  }

  &-label {
    @extend %size-micro;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 5px;

    #{$form-root}-group.has-error & {
      color: map-get($color-map, error);
    }

  }


}









.user {
  display: flex;
  align-items: center;

  &__img {
    max-height: 4rem;
    max-width: 4rem;
    margin:0 1rem 0 0;
    border: 3px solid map-get($color-map, secondary);
    border-radius: 25px;  
  }

  &__text {
    @extend %size-base;
    white-space: nowrap;
  }


}